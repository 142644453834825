/* eslint-disable no-console */
import { register } from "register-service-worker";
import { createNotificationSubscription } from "@/graphql/mutations";
import { fetcherAmplify } from "@/libs/fetcher";
import store from "./store";

const config = { pushKey: "BBUrdEhE4oEU_B_ajVe6geSQUMuVXNzMkhqc7Wt6JuMhgW0SU37QP6_tBKZSsdHqr8JutNBTksC7ky30NsYAxQ8" };
// if (process.env.NODE_ENV === "production") {

register(`notification-service-worker.js`, {
  registrationOptions: {
    type: "module",
  },
  async ready(registration) {
    console.log("App is being served from cache by a service worker.\n", registration);
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(config.pushKey),
    });
    console.log("Notification sub ready", subscription);
  },
  async registered(registration) {
    console.log("Service worker has been registered.", registration);
    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(config.pushKey),
      });
      store.commit("agentChatChime/setNotificationSubscription", subscription);
      console.log("Notification sub registered", subscription);
    } catch (error) {
      console.error("Service worker sub fail error", error);
    }
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    console.log("New content is available; please refresh.");
  },
  offline() {
    console.log("No internet connection found. App is running in offline mode.");
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});
// }
function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
