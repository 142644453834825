import { API, graphqlOperation } from "aws-amplify";
import { fetcherAmplify } from "@/libs/fetcher";
import { getChimeAgentMessages, getAgentMessageChannel } from "@/graphql/queries";
import {
  createChimeMessageChannel,
  deleteChimeAgentMessageChannel,
  addChimeUserToChannel,
  sendChimeAgentMessage,
  updateChimeAgentMessage,
  deleteChimeAgentMessage,
  createNotificationSubscription,
} from "@/graphql/mutations";
import {} from "@/common/constant";
import {} from "@connectpath/common";
import {} from "../agent.module";
import _ from "lodash";
export default {
  namespaced: true,
  state: {
    userId: "",
    activeConversationId: "",
    messages: [],
    activeConversationChannelArn: "",
    notificationSubscription: {},
  },
  getters: {},
  mutations: {
    setActiveConversationChannelArn(state, payload) {
      state.activeConversationChannelArn = payload;
    },
    setActiveConversationId(state, payload) {
      state.activeConversationId = payload;
    },
    setNotificationSubscription(state, payload) {
      console.log("notification sub in store", payload);
      state.notificationSubscription = payload;
    },
  },
  actions: {
    async getChatSetup({ commit, dispatch, state }, payload) {
      try {
        const res = await fetcherAmplify().graphql({
          query: getAgentMessageChannel,
          variables: {
            input: payload,
          },
        });
        if (res.getAgentMessageChannel?.ChannelArn) {
          commit("setActiveConversationChannelArn", res.getAgentMessageChannel.ChannelArn);
          commit("setActiveConversationId", res.getAgentMessageChannel.id);
        } else {
          dispatch("setupChat", payload);
        }
      } catch (error) {
        console.log("Chat setup fetching failed", error);
      }
    },
    async setupChat({ commit }, payload) {
      try {
        const res = await fetcherAmplify().graphql({
          query: createChimeMessageChannel,
          variables: {
            input: payload,
          },
        });
        if (res.createAgentMessageChannel?.ChannelArn) {
          commit("setActiveConversationChannelArn", res.createAgentMessageChannel.ChannelArn);
        }
      } catch (error) {
        console.log("Chat setup failed", error);
      }
    },
    async sendMessage({ commit, state }, payload) {
      try {
        const res = await fetcherAmplify().graphql({
          query: sendChimeAgentMessage,
          variables: {
            input: payload,
          },
        });
        if (res.sendAgentMessage) {
          const message = {
            InstanceId: payload.InstanceId,
            Author: payload.UserArn,
            id: res.sendAgentMessage.MessageId,
            ConversationId: state.activeConversationChannelArn,
            Body: payload.Content,
            MessageStatus: "SENT",
            SentAt: new Date().toISOString(),
            CreatedAt: new Date().toISOString(),
            UpdatedAt: undefined,
          };
          commit(
            "chat/addNewChatMessage",
            { conversationId: state.activeConversationId, message: message },
            {
              root: true,
            }
          );
        }
      } catch (error) {
        console.log("Chat sending failed", error);
      }
    },
    async getMessages({ commit, state }, payload) {
      try {
        const res = await fetcherAmplify().graphql({
          query: getChimeAgentMessages,
          variables: payload,
        });
        const messages = _.map(res.getAgentMessage.Messages, (msg) => {
          return {
            InstanceId: payload.InstanceId,
            Author: msg.Sender.Name,
            id: msg.MessageId,
            ConversationId: state.activeConversationId,
            Body: msg.Content,
            MessageStatus: "SENT",
            SentAt: msg.CreatedTimestamp,
            CreatedAt: msg.CreatedTimestamp,
            UpdatedAt: msg.CreatedTimestamp !== msg.LastUpdatedTimestamp ? msg.LastUpdatedTimestamp : undefined,
          };
        });
        const conversation = [
          {
            Messages: messages,
            id: state.activeConversationId,
          },
        ];
        commit("chat/setConversations", conversation, {
          root: true,
        });
      } catch (error) {
        console.log("Chat messages fetching failed", error);
      }
    },
    async deleteMessage({ commit }, payload) {
      try {
        const res = await fetcherAmplify().graphql({
          query: deleteChimeAgentMessage,
          variables: {
            input: payload,
          },
        });
      } catch (error) {
        console.log("Chat setup failed", error);
      }
    },
    async updateMessage({ commit, state }, payload, SentAt) {
      try {
        const res = await fetcherAmplify().graphql({
          query: updateChimeAgentMessage,
          variables: {
            input: payload,
          },
        });
        if (res.updateAgentMessage) {
          const message = {
            InstanceId: payload.InstanceId,
            Author: payload.UserArn,
            id: res.updateAgentMessage.MessageId,
            ConversationId: state.activeConversationChannelArn,
            Body: payload.Content,
            SentAt: SentAt,
            MessageStatus: "SENT",
            CreatedAt: SentAt,
            UpdatedAt: new Date().toISOString(),
          };
          commit(
            "chat/updateAgentChatMessage",
            {
              conversationId: state.activeConversationId,
              newMessage: message,
              tempMessageId: res.updateAgentMessage.MessageId,
            },
            {
              root: true,
            }
          );
        }
      } catch (error) {
        console.log("Chat setup failed", error);
      }
    },
    async addMessageInList({ commit }, payload) {
      commit(
        "chat/addNewChatMessage",
        { conversationId: payload.ConversationId, message: payload },
        {
          root: true,
        }
      );
    },
    async saveNotificationSubscription({ commit, state }) {
      let payload = {
        Subscription: JSON.stringify(state.notificationSubscription),
        Topic: "ChimeMessageNotificationTopic",
      };
      const res = await fetcherAmplify().graphql({
        query: createNotificationSubscription,
        variables: {
          input: payload,
        },
      });
    },
  },
};
